const screen = {
    HOME: {
        path: "/home",
        name: "Trang chủ"
    },

    GAME: {
        path: "/game",
        name: "Games"
    },
    OFFICE: {
        path: "/office",
        name: "Phần mềm văn phòng"
    },
    GRAPHIC: {
        path: "/graphic",
        name: "Phần mềm đồ họa"
    },
    TECHNIQUE: {
        path: "/technique",
        name: "Phần mềm kỹ thuật"
    },
    OS: {
        path: "/os",
        name: "Windows, hệ điều hành, ghost"
    },
    COMMON: {
        path: "/common",
        name: "Ai cũng cần"
    },
    DETAIL: {
        path: "detail/:postCode",
        name: "Bài viết"
    },
    NOT_FOUND: {
        path: "/404",
        name: "404 Not found"
    },


    ADMIN: {
        path: "admin",
        name: "admin"
    },
    ADMIN_DETAIL: {
        path: "detail",
        name: "Chi tiết"
    },

    LOGIN: {
        path: "login",
        name: "Đăng nhập"
    },
    REGISTER: {
        path: "register",
        name: "Đăng ký"
    },
    CHANGE_PASSWORD: {
        path: "change-password",
        name: "Đổi mật khẩu"
    },
    USER_INFO: {
        path: "user-info",
        name: "Thông tin người dùng"
    },
}

export default screen